import { VStack } from '@chakra-ui/react';
import { useRouteData } from 'hooks';
import dynamic from 'next/dynamic';
import { useRef } from 'react';
import { GutenbergRenderer } from './GutenbergRenderer';
import StructuredData from './StructuredData';
import MenuSlider from 'components/shared/MenuSlider';

const Sources = dynamic(() => import('components/shared/Sources'), {
  ssr: false
});

const HERO_blocks = ['acf/arageek-bio-hero'];

export const Content: React.FC = ({ data, article_summary }) => {
  const contentRef = useRef(null);

  const { gutenberg_blocks, id, type, acf, related_items, object_type } = data;

  const blocks = gutenberg_blocks
    ?.slice(
      type === 'page' && gutenberg_blocks?.[0].blockName !== 'acf/blog-hero'
        ? 1
        : type === 'page' && gutenberg_blocks?.[0].blockName === 'acf/blog-hero'
        ? 2
        : 0
    )
    .filter(({ blockName }) => !HERO_blocks.includes(blockName));

  const MoviesArray = gutenberg_blocks?.filter(({ blockName }) => {
    return blockName === 'acf/arageek-movies-info';
  });

  return (
    <>
      <VStack
        ref={contentRef}
        sx={{ counterReset: 'css-counter 0' }}
        align={'stretch'}
        spacing={4}
      >
        <GutenbergRenderer
          {...{
            related_items,
            acf,
            type,
            id,
            blocks,
            object_type
          }}
          postId={id}
          article_summary={article_summary}
        />
      </VStack>
      <Sources contentRef={contentRef} />
      {Array.isArray(MoviesArray) && <StructuredData data={MoviesArray} />}
    </>
  );
};

export const HeroContent: React.FC = () => {
  const data = useRouteData();
  const blocks = data?.gutenberg_blocks?.filter(({ blockName }) =>
    HERO_blocks.includes(blockName)
  );

  if (
    !Array.isArray(data?.gutenberg_blocks) ||
    data?.gutenberg_blocks?.length === 0
  ) {
    return null;
  }

  return (
    <VStack align={'stretch'} spacing={4}>
      <GutenbergRenderer
        blocks={data?.type === 'page' ? [data?.gutenberg_blocks?.[0]] : blocks}
        postId={data?.id}
      />
      {data?.type === 'page' && <MenuSlider />}

      {data?.gutenberg_blocks?.[0]?.blockName === 'acf/blog-hero' && (
        <GutenbergRenderer
          blocks={
            data?.type === 'page' ? [data?.gutenberg_blocks?.[1]] : blocks
          }
          postId={data?.id}
        />
      )}
    </VStack>
  );
};
