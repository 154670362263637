import { AdsSlot, VideoAdsSlot } from 'components/ads';
import AdsenseSLots from 'components/ads/AdsenseSLots';

import { RelatedSlider } from 'components/shared';
import dynamic from 'next/dynamic';
import { Fragment } from 'react';
import { BlocksDirectory } from './blocks';
import { useMenu, useSiteConfig } from 'hooks';
import ArticleSummary from './acf/ArticleSummary';
const DeviceSpecification = dynamic(
  () => import('components/shared/DeviceSpecification'),
  {
    ssr: false
  }
);

export const GutenbergRenderer = ({
  blocks,
  postId,
  type,
  acf,
  related_items,
  article_summary,
  object_type
}) => {
  const FallbackComponent = BlocksDirectory?.['fallback'];
  const { adsSlots } = useSiteConfig();
  const MainMenu = useMenu('main-menu');

  return (
    <>
      {adsSlots && adsSlots[0]?.header?.client_id && (
        <AdsenseSLots data={adsSlots[0]?.header} />
      )}
      {blocks
        ?.filter(({ blockName }) => Boolean(blockName))
        ?.map((props, i) => {
          const { blockName } = props;
          const Block = BlocksDirectory?.[blockName];

          if (Boolean(Block)) {
            return (
              <Fragment key={`${postId}_${i}`}>
                <AdsSlot place={`${postId}_post_content_${i}`} />
                {adsSlots &&
                  adsSlots[0]?.in_article_1?.client_id &&
                  i === 2 && <AdsenseSLots data={adsSlots[0]?.in_article_1} />}
                {article_summary && i == 0 && (
                  <ArticleSummary article_summary={article_summary} />
                )}
                {type === 'tech' && i === 2 && (
                  <DeviceSpecification acf={acf} />
                )}
                {adsSlots &&
                  adsSlots[0]?.in_article_2?.client_id &&
                  i === 3 && <AdsenseSLots data={adsSlots[0]?.in_article_2} />}
                {type !== 'page' && i === 3 && <VideoAdsSlot />}
                {i == blocks.length - 2 && (
                  <RelatedSlider
                    key={`carousel${i}`}
                    items={related_items}
                    type={type}
                  />
                )}
                {i == blocks.length - 1 &&
                  adsSlots &&
                  adsSlots[0]?.sidebar?.client_id && (
                    <AdsenseSLots data={adsSlots[0]?.sidebar} />
                  )}
                <Block
                  {...props}
                  postId={postId}
                  blockIndex={i}
                  normalVertical={
                    i === 0 && blockName === 'acf/vertical-cards' ? true : false
                  }
                />
              </Fragment>
            );
          }
          if (Boolean(FallbackComponent)) {
            return <FallbackComponent key={i} {...props} />;
          }
          return null;
        })}
    </>
  );
};

export const GutenbergChildrenRenderer: React.FC = ({ blocks }) => {
  const FallbackComponent = BlocksDirectory?.['fallback'];
  return (
    <>
      {blocks
        ?.filter(({ blockName }) => blockName !== null)
        ?.map((props, i) => {
          const { blockName } = props;
          const Block = BlocksDirectory?.[blockName];

          if (Boolean(Block)) {
            return <Block key={i} {...props} />;
          }
          if (Boolean(FallbackComponent)) {
            return <FallbackComponent key={i} {...props} />;
          }
          return null;
        })}
    </>
  );
};
